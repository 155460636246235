import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// Countdown timer
import Countdown from "react-countdown"

// images
import logo from "../../../../../images/home/section-one/logo.png"

// Nightlife launch date
const event = new Date("24 December 2020 12:00:00 GMT+1")
const ISOevent = event.toISOString()

const LaunchComponent = () => {
  return (
    <Launch>
      <Container>
        <Logo />
        <Nightlife>
          <Heading>Nightlife Opens</Heading>
          <Timer date={ISOevent} />
          <Location>In Copenhagen + Roskilde</Location>
        </Nightlife>
        <StyledLink to="/home">
          <Join>Join Now</Join>
        </StyledLink>
      </Container>

      <Pointer>
        <span role="img" aria-label="pointing-right">
          👇
        </span>
      </Pointer>
    </Launch>
  )
}

export default LaunchComponent
const Launch = styled.div`
  // resetting
  margin: 0;
  padding: 0;

  // size
  height: 100vh;
  width: 100%;

  // display-options
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  // media query
  @media screen and (min-width: 768px) {
    // size
    width: 50%;
  }
`

const Container = styled.div`
  // resetting
  margin: 0;
  padding: 0;

  // size
  height: 100vh;
  width: 100%;

  // display-options
  align-self: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  // media query
  @media screen and (min-width: 768px) {
    // size
    width: 80%;

    // display-options
    align-self: flex-end;
    align-items: flex-start;
  }

  @media screen and (min-width: 1800px) {
    // size
  }
`

const Image = styled.img`
  // resetting
  margin: 0;
  padding: 0;

  // size
  width: auto;
  height: auto;

  // media query
  @media screen and (min-width: 768px) {
    // size
    width: 80%;
  }

  @media screen and (min-width: 1800px) {
    // size
    width: 60%;
  }
`

const Logo = styled(Image)`
  content: url(${logo});
  max-width: 69%;
`
const Nightlife = styled.div`
  // media query normal
  @media screen and (min-width: 768px) {
    // size
    width: 100%;
  }
`

const Heading = styled.h1`
  // resetting
  margin: 0;
  padding: 0;

  // typography
  font-size: 2rem;
  font-weight: bold;

  // alignment
  text-align: center;

  // color
  color: #fff;

  // media query
  @media screen and (min-width: 768px) {
    // alignment
    text-align: left;
  }

  // media query for larger headings
  @media screen and (min-width: 1400px) {
    // size
    font-size: 3rem;
  }
`

const Timer = styled(Countdown)`
  // margin & padding
  margin: 20px 0;
  padding: 0;

  // typography
  font-size: 2.5rem;

  // alignment
  text-align: center;

  // color
  color: #fdde68;

  // display-options
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 768px) {
    // display-options
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
  }
`

const Location = styled.p`
  // margin & padding
  margin: 0;
  padding: 0;

  // typography
  font-size: 1.3rem;
  font-style: italic;

  // alignment
  text-align: center;

  // color
  color: #999;

  @media screen and (min-width: 768px) {
    // alignment
    text-align: left;
  }
`

const Join = styled.div`
  // resetting
  margin: 0;
  padding: 0;

  // size
  height: 60px;
  width: 100%;
  border-radius: 50px;

  // display-options
  display: flex;
  align-items: center;
  justify-content: center;

  // typography
  font-size: 1.5rem;
  font-weight: bold;

  background-color: #fdde68;

  -webkit-box-shadow: 0px 0px 30px 0px rgba(234, 200, 68, 0.6);
  -moz-box-shadow: 0px 0px 30px 0px rgba(234, 200, 68, 0.6);
  box-shadow: 0px 0px 30px 0px rgba(234, 200, 68, 0.6);

  @media screen and (min-width: 1600px) {
    // size
    height: 70px;
  }

  @media screen and (min-width: 1920px) {
    // size
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  // resetting
  margin: 0;
  padding: 0;

  // size
  height: 60px;
  width: 50%;
  border-radius: 50px;

  // typography
  font-size: 1.5rem;

  // color
  color: #333;

  // display-options
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 550px) {
    // size
    width: 40%;
  }

  @media screen and (min-width: 768px) {
    // size
    width: 60%;
  }

  @media screen and (min-width: 1600px) {
    // size
    width: 50%;
  }

  @media screen and (min-width: 1920px) {
    // size
    width: 40%;
  }

  @media screen and (min-width: 2800px) {
    // size
    width: 30%;
  }
`
const Pointer = styled.p`
  // margin & padding
  margin: 0;
  padding: 0;

  // typography
  font-size: 4rem;

  // alignment
  text-align: center;

  // media query
  @media screen and (min-width: 768px) {
    // display settings
    display: none;
  }
`
